import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ProductCard from '../../components/ProductCard/ProductCard';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AiOutlineHome } from 'react-icons/ai';
import axios from 'axios';

function CollectionView() {
    const { collectionName } = useParams();
    const collection_name = collectionName.replace(/-/g, ' ');
    const baseURL = 'https://theattarco.com/';
    const navigate = useNavigate();

    const [collectionItems, setCollectionItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true); // Track if more data is available

    const itemsPerPage = 20; // Adjust based on preference

    useEffect(() => {
        fetchProduct(page);
    }, [collection_name, page]);

    const fetchProduct = async (page) => {
        try {
            const sessionId = sessionStorage.getItem('sessionId');
            const username = sessionStorage.getItem('username');
            const response = await axios.get(`${baseURL}api/client_api/collections/getCollections.php`, {
                params: {
                    name: collection_name,
                    ipadd: sessionId || 'na',
                    email: username || 'na',
                    page: page,
                    limit: itemsPerPage,
                }
            });
            const fetchedItems = response.data.data;
            if (fetchedItems.length === 0) {
                setHasMore(false); // No more data
            } else {
                setCollectionItems(prevItems => [...prevItems, ...fetchedItems]); // Append new items
            }
            setIsLoading(false);
        } catch (err) {
            setError(err.message);
            setIsLoading(false);
        }
    };

    const loadMore = () => {
        if (hasMore) {
            setPage(prevPage => prevPage + 1); // Load next page
        }
    };

    return (
        <div>
            <Container fluid className='sectionView mt-3 px-lg-5 px-md-5 px-sm-5'>
                <div className='breadcrumb'>
                    <a href='/' className='breadcrumb-item text-white me-2'><AiOutlineHome size={20} /></a>
                    <a href='/collections' className='breadcrumb-item text-white me-2'>Collections</a>
                    <div className='breadcrumb-item text-white me-2'>{collection_name}</div>
                </div>
                <h1 className="text-white pt-2 fw-bold">{collection_name}</h1>
                
                <Row className="g-2">
                    {collectionItems.map((item, index) => (
                        <Col xs={6} md={6} lg={3} key={index}>
                            <ProductCard item={item} baseUrl={baseURL} wishlist={item.in_wishlist} cart={item.in_cart} />
                        </Col>
                    ))}
                </Row>
                {isLoading && <div>Loading...</div>}
                {/* {hasMore && !isLoading && (
                    <div className="text-center">
                        <button onClick={loadMore} className="btn btn-primary mt-3">Load More</button>
                    </div>
                )} */}
            </Container>
        </div>
    );
}

export default CollectionView;
