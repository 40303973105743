import React, { useState, useEffect } from 'react';
import { Form, Button, Col, Row, Container, Card, InputGroup, Breadcrumb, FormControl, ProgressBar } from 'react-bootstrap';
import './Checkout.css';
import { Link, useLocation } from 'react-router-dom';
import razorPay from '../../assets/images/razorpay.png';
import { useNavigate } from 'react-router-dom';
import { AiOutlineArrowRight, AiOutlineHome, AiOutlineTag } from 'react-icons/ai';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import SecureLS from "secure-ls";
import axios from 'axios';

const Checkout = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const ls = new SecureLS({ encodingType: 'aes' });
    const [iscreateAccount, setIscreateAccount] = useState(false)
    const keyId = process.env.REACT_APP_KEY_ID_PROD;

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    useEffect(() => {
        scrollToTop();
        const username = ls.get('userEmail');
        if (!username) {
            setIscreateAccount(true)
        } else {
            getMyDetails(username);
        }
    }, [navigate]);

    const getMyDetails = async (username) => {
        try {
            const email = ls.get('userEmail');
            if (email) {
                const response = await axios.get(`https://karstar.org/api/client_api/authentication/account/index.php?email=${encodeURIComponent(username)}`);
                const myDetails = response.data;
                setFormValues(prevValues => ({
                    ...prevValues,
                    email: myDetails.Email_Id || '',
                    phone: myDetails.Mobile_No || '',
                    firstName: myDetails.Firstname || '',
                    lastName: myDetails.Lastname || '',
                    address: myDetails.address1 || '',
                    address2: myDetails.address2 || '',
                    city: myDetails.city || '',
                    state: myDetails.states || '',
                    zip: myDetails.Zip || ''
                }));
            } else {
                console.error("User email is not found in local storage.");
            }
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    var { item, isBuyNow, isCart, selectedSize } = location.state || {};
    if (!Array.isArray(item)) {
        item = [item];
    }

    if (isBuyNow && selectedSize) {
        item = item.map(product => {
            const sizePrice = product.price.find(priceEntry => priceEntry.weight + 'ml' === selectedSize);
            return { ...product, price: sizePrice, qty: 1 }; // Add qty with value 1
        });
    }

    const baseUrl = 'https://theattarco.com/';

    const totalQuantity = item.reduce((total, item) => total + parseInt(item.qty ? item.qty : 1), 0);
    const totalMRP = item.reduce((total, item) => total + parseFloat(item.price.basePrice) * parseInt(item.qty ? item.qty : 1), 0);
    const totalDiscount = item.reduce((total, item) => total + (parseFloat(item.price.basePrice) - parseFloat(item.price.discountedPrice)) * parseInt(item.qty ? item.qty : 1), 0);
    const totalAmount = item.reduce((total, item) => total + parseFloat(item.price.discountedPrice) * parseInt(item.qty ? item.qty : 1), 0);

    const [formValues, setFormValues] = useState({
        email: '',
        emailValid: null,
        phone: '',
        phoneValid: null,
        firstName: '',
        firstNameValid: null,
        lastName: '',
        lastNameValid: null,
        country: 'India',
        address: '',
        addressValid: null,
        address2: '',
        city: '',
        cityValid: null,
        state: '',
        stateValid: null,
        zip: '',
        zipValid: null,
        password: '',
        passwordValid: null,
        confirmPassword: '',
        confirmPasswordValid: null,
        confirmPasswordMatch: null
    });

    const [isFormValid, setIsFormValid] = useState(false);
    const [shippingPrice, setShippingPrice] = useState(0);
    const [createAccount, setCreateAccount] = useState(false);
    const [showPassword, setShowPassword] = useState(false); // Show/hide password toggle state
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Show/hide confirm password toggle 
    const [passwordMatchError, setPasswordMatchError] = useState('');
    const [showPasswordStrength, setShowPasswordStrength] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [passwordStrengthText, setPasswordStrengthText] = useState('');
    useEffect(() => {
        const { email, phone, firstName, lastName, address, city, state, zip, password, confirmPassword } = formValues;
        if (
            validateEmail(email) &&
            validatePhoneNumber(phone) &&
            validateRequired(firstName) &&
            validateRequired(lastName) &&
            validateRequired(address) &&
            validateRequired(city) &&
            validateRequired(state) &&
            validateRequired(zip) &&
            (!createAccount || (validateRequired(password) && validateRequired(confirmPassword) && validatePasswordMatch(password, confirmPassword)))
        ) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    }, [formValues, createAccount]);

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const validatePhoneNumber = (phone) => {
        const re = /^\d{10}$/;
        return re.test(phone);
    };

    const validateZipCode = (zip) => {
        const re = /^\d{6}$/;
        return re.test(zip);
    };

    const validateRequired = (value) => {
        return value.trim() !== '';
    };

    const validatePasswordMatch = (password, confirmPassword) => {
        handleConfirmPasswordChange(password, confirmPassword)
        return password === confirmPassword;
    };

    const calculatePasswordStrength = (password) => {
        setShowPasswordStrength(password.length > 0);
        let strength = 0;
        if (password.length >= 8) strength += 1;
        if (/[a-z]/.test(password)) strength += 1;
        if (/[A-Z]/.test(password)) strength += 1;
        if (/[0-9]/.test(password)) strength += 1;
        if (/[^a-zA-Z0-9]/.test(password)) strength += 1;

        setPasswordStrength(strength);

        if (strength === 5) {
            setPasswordStrengthText('Strong');
        } else if (strength >= 3) {
            setPasswordStrengthText('Medium');
        } else {
            setPasswordStrengthText('Weak');
        }
    };

    const handleChange = (e) => {
        const { id, value } = e.target;

        let isValid = false;

        switch (id) {
            case 'email':
                isValid = validateEmail(value);
                break;
            case 'phone':
                isValid = validatePhoneNumber(value);
                break;
            case 'zip':
                isValid = validateZipCode(value);
                break;
            default:
                isValid = validateRequired(value);
                break;
        }

        setFormValues(prevState => ({
            ...prevState,
            [id]: value,
            [`${id}Valid`]: isValid
        }));
    };

    const handleCheckboxChange = (e) => {
        setCreateAccount(e.target.checked);
    };

    // Toggle show/hide password
    const toggleShowPassword = () => setShowPassword(!showPassword);
    const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

    const handleConfirmPasswordChange = (password, confirmPassword) => {

        if (password === confirmPassword) {
            setPasswordMatchError('Passwords do not match.');
        } else {
            setPasswordMatchError('');
        }
    };

    const fetchShippingPrice = async (zip) => {
        try {
            // Extract the prefix from the ZIP code
            const zipPrefix = zip.slice(0, 2); // Assuming ZIP code is a string

            // Check if the zipPrefix is between 67 to 69 (Kerala)
            const isKerala = zipPrefix >= '67' && zipPrefix <= '69';

            // Fixed shipping prices
            const keralaPrice = 70;
            const otherPrice = 150;

            // Calculate the total shipping price
            const shipping = item.reduce((acc, item) => {
                const deliveryPrice = isKerala ? keralaPrice : otherPrice;

                return deliveryPrice;
            }, 0);

            setShippingPrice(shipping);
        } catch (error) {
            console.error('Error fetching shipping price:', error);
        }
    };


    useEffect(() => {
        if (formValues.zip) {
            fetchShippingPrice(formValues.zip);
        }
    }, [formValues.zip]);

    const handlePayment = async () => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = sessionStorage.getItem('username');

        // Step 1: Attempt user registration if needed
        if (createAccount) {
            try {
                const registerResponse = await fetch('https://theattarco.com/api/client_api/authentication/register/checkoutregister.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ formValues })
                });

                const registerData = await registerResponse.json();

                if (!registerData.success) {
                    alert((registerData.message || 'An error occurred'));
                    return; // Stop further execution if registration fails
                }
            } catch (error) {
                console.log('An error occurred during registration: ' + error.message);
                return; // Stop further execution if an exception occurs during registration
            }
        }

        // Step 2: Create the order
        try {
            const response = await fetch('https://theattarco.com/api/client_api/gateway/createorder.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ amount: totalAmount + shippingPrice })
            });

            const data = await response.json();
            // console.log(data);

            // if (!data.success) {
            //     alert('Order creation failed: ' + (data.error || 'An error occurred'));
            //     return; // Stop further execution if order creation fails
            // }

            const options = {
                key: keyId,
                amount: totalAmount * 100, // Amount in paise
                currency: 'INR',
                name: 'The Attar Co.',
                description: 'You are making payment for an order on The Attar Co. website',
                order_id: data.orderId,
                handler: async function (response) {
                    try {
                        const orderResponse = await fetch('https://theattarco.com/api/client_api/place/placeorder.php', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                items: item,
                                formValues,
                                paid: totalAmount + shippingPrice,
                                delivery_charges: shippingPrice,
                                paymentId: response.razorpay_payment_id,
                                orderId: data.customOrderId, // Use the custom order ID
                                razorpayOrderId: response.razorpay_order_id, // Add the Razorpay order ID,
                                isBuyNow: isBuyNow,
                                isCart: isCart,
                                sessionId: sessionId || 'na',
                                username: username || 'na'
                            })
                        });

                        const orderData = await orderResponse.json();

                        if (orderData.success) {
                            navigate('/placed', {
                                state: {
                                    orderId: data.customOrderId,
                                    address: formValues,
                                    totalAmount: totalAmount + shippingPrice,
                                    orderDate: '2024-07-18',
                                    items: item,
                                }
                            });

                            try {
                                const orderid = data.customOrderId;
                                const response = await axios.get('https://admin.theattarco.com/api/v1/notifications/w3send.php', {
                                    params: { orderid } // Passing the orderId as a query parameter
                                });

                                const registerData = response.data;

                                if (!registerData.success) {
                                    alert(registerData.message || 'An error occurred');
                                    return; // Stop further execution if registration fails
                                }
                            } catch (error) {
                                console.log('An error occurred during registration: ' + error.message);
                                return; // Stop further execution if an exception occurs during registration
                            }

                        } else {
                            alert('Failed to save order: ' + (orderData.error || 'An error occurred'));
                        }
                    } catch (error) {
                        alert('An error occurred while placing the order: ' + error.message);
                    }
                },
                prefill: {
                    name: `${formValues.firstName} ${formValues.lastName}`,
                    email: formValues.email,
                    contact: formValues.phone
                },
                notes: {
                    address: formValues.address
                },
                theme: {
                    color: '#3399cc'
                }
            };

            const rzp1 = new window.Razorpay(options);
            rzp1.open();
        } catch (error) {
            alert('An error occurred during order creation: ' + error.message);
        }
    };

    const [promoCode, setPromoCode] = useState('');


    const handleApplyPromoCode = () => {
        console.log(promoCode);
    };

    const Breadcrumb = () => {
        return (
            <div className='breadcrumb-container'>
                <div className='breadcrumb'>
                    <Link to='/' className='breadcrumb-item text-white me-2'><AiOutlineHome size={20} /></Link>
                    <Link to='/cart' className='breadcrumb-item text-white me-2'>Cart</Link>
                    <div className='breadcrumb-item text-white me-2'>Checkout</div>
                </div>
            </div>
        );
    };


    return (
        <div>
            <section className='sectionView pb-5'>
                <Container fluid className="px-lg-5 px-md-5 px-sm-5">
                    <Breadcrumb />

                    <Row className='d-flex justify-content-center'>
                        <div>
                            <h4 className='title text-white py-2 text-uppercase'><span className='gradient-text'>Checkout</span></h4>
                        </div>
                        <Col lg={7}>
                            <Card className='mb-2 rounded-4'>
                                <Card.Body>
                                    <Form>
                                        {/* Contact Section */}
                                        <h5 className='fs-5 text-start'>Contact Info</h5>
                                        <Row>
                                            <Col>
                                                <Form.Group controlId="email">
                                                    <Form.Label className='mt-2 fs-6 text-start w-100'>Email</Form.Label>
                                                    <InputGroup>
                                                        <InputGroup.Text className='rounded-start-5  border-0 bg-light'>@</InputGroup.Text>
                                                        <Form.Control type="email" className={`bg-light border-0 rounded-end-5 me-2 py-2 ${formValues.emailValid === false ? 'is-invalid' : ''}`} placeholder="Enter e-mail address" value={formValues.email} onChange={handleChange} />
                                                        {formValues.emailValid === false && <div className="invalid-feedback text-start">Please enter a valid email address.</div>}
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="phone">
                                                    <Form.Label className='mt-2 fs-6 text-start w-100'>Phone</Form.Label>
                                                    <InputGroup>
                                                        <InputGroup.Text className='rounded-start-5 border-0'>+91</InputGroup.Text>
                                                        <Form.Control type="number" className={`bg-light border-0 rounded-end-5 me-2 py-2 ${formValues.phoneValid === false ? 'is-invalid' : ''}`} minLength={'10'} maxLength={'10'} placeholder="Enter mobile number" value={formValues.phone} onChange={handleChange} />
                                                        {formValues.phoneValid === false && <div className="invalid-feedback text-start">Please enter a valid 10 Digit phone number without +91. </div>}
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <hr />
                                        {/* Personal Info Section */}
                                        <h5 className='fs-5 text-start w-100'>Personal Info</h5>
                                        <Row>
                                            <Col>
                                                <Form.Group controlId="firstName">
                                                    <Form.Label className='mt-2 fs-6 text-start w-100'>First Name</Form.Label>
                                                    <Form.Control type="text" className={`bg-light border-0 rounded-5 me-2 py-2 ${formValues.firstNameValid === false ? 'is-invalid' : ''}`} placeholder="First name" value={formValues.firstName} onChange={handleChange} />
                                                    {formValues.firstNameValid === false && <div className="invalid-feedback text-start">First name is required.</div>}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="lastName">
                                                    <Form.Label className='mt-2 fs-6 text-start w-100'>Last Name</Form.Label>
                                                    <Form.Control type="text" className={`bg-light border-0 rounded-5 me-2 py-2 ${formValues.lastNameValid === false ? 'is-invalid' : ''}`} placeholder="Last name" value={formValues.lastName} onChange={handleChange} />
                                                    {formValues.lastNameValid === false && <div className="invalid-feedback text-start">Last name is required.</div>}
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <hr />

                                        {/* Delivery Section */}
                                        <h5 className='fs-5  text-start w-100'>Delivery</h5>

                                        <Form.Group controlId="country">
                                            <Form.Label className='mt-2 fs-6 text-start w-100'>Country</Form.Label>
                                            <Form.Control type="text" className='bg-light border-0 rounded-5 me-2 py-2' value={formValues.country} readOnly />
                                        </Form.Group>

                                        <Form.Group controlId="address">
                                            <Form.Label className='mt-2 fs-6 text-start w-100'>Address</Form.Label>
                                            <Form.Control type="text" placeholder="Door no, street address etc" className={`bg-light border-0 rounded-5 me-2 py-2 ${formValues.addressValid === false ? 'is-invalid' : ''}`} value={formValues.address} onChange={handleChange} />
                                            {formValues.addressValid === false && <div className="invalid-feedback text-start">Address is required.</div>}
                                        </Form.Group>
                                        <Form.Group controlId="address2">
                                            <Form.Label className='mt-2 fs-6 text-start w-100'>Apartment, suite, etc. (optional)</Form.Label>
                                            <Form.Control type="text" className='bg-light border-0  rounded-5 me-2 py-2' value={formValues.address2} onChange={handleChange} />
                                        </Form.Group>
                                        <Row>
                                            <Col>
                                                <Form.Group controlId="city">
                                                    <Form.Label className='mt-2 fs-6 text-start w-100'>City</Form.Label>
                                                    <Form.Control type="text" className={`bg-light border-0 rounded-5 me-2 py-2 ${formValues.cityValid === false ? 'is-invalid' : ''}`} placeholder="City" value={formValues.city} onChange={handleChange} />
                                                    {formValues.cityValid === false && <div className="invalid-feedback text-start">City is required.</div>}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="state">
                                                    <Form.Label className='mt-2 fs-6 text-start w-100'>State</Form.Label>
                                                    <Form.Control type="text" className={`bg-light border-0 rounded-5 me-2 py-2 ${formValues.stateValid === false ? 'is-invalid' : ''}`} placeholder="State" value={formValues.state} onChange={handleChange} />
                                                    {formValues.stateValid === false && <div className="invalid-feedback text-start">State is required.</div>}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group controlId="zip">
                                                    <Form.Label className='mt-2 fs-6 text-start w-100'>PIN Code</Form.Label>
                                                    <Form.Control type="number" className={`bg-light border-0 rounded-5 me-2 py-2 ${formValues.zipValid === false ? 'is-invalid' : ''}`} placeholder="Pincode" value={formValues.zip} onChange={handleChange} />
                                                    {formValues.zipValid === false && <div className="invalid-feedback text-start">6 Digit pincode is required.</div>}
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        {iscreateAccount &&
                                            <Form.Group controlId="createAccountCheckbox" className="mt-3">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Create an account?"
                                                    checked={createAccount}
                                                    onChange={handleCheckboxChange}
                                                />
                                            </Form.Group>
                                        }

                                        {createAccount && (
                                            <>
                                                <Row>
                                                    <Col className='position-relative'>
                                                        <Form.Group controlId="password">
                                                            <Form.Label className='mt-2 fs-6 text-start w-100'>Password</Form.Label>
                                                            <Form.Control type={showPassword ? 'text' : 'password'}
                                                                className={`bg-light border-0 rounded-5 me-2 py-2 ${formValues.passwordValid === false ? 'is-invalid' : ''}`} placeholder="Enter password" value={formValues.password} onChange={(e) => {
                                                                    handleChange(e);
                                                                    calculatePasswordStrength(e.target.value);
                                                                }}
                                                            />
                                                            {formValues.passwordValid === false && <div className="invalid-feedback text-start">Password is required.</div>}
                                                        </Form.Group>
                                                        <span onClick={toggleShowPassword} className="position-absolute end-0 translate-middle-y me-5" style={{ top: '60px' }}>
                                                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                                                        </span>
                                                    </Col>
                                                    <Col className='position-relative'>
                                                        <Form.Group controlId="confirmPassword"
                                                        >
                                                            <Form.Label className='mt-2 fs-6 text-start w-100'>Confirm Password</Form.Label>
                                                            <Form.Control
                                                                type={showConfirmPassword ? 'text' : 'password'}
                                                                className={`bg-light border-0 rounded-5 me-2 py-2 ${formValues.confirmPasswordValid === false || validatePasswordMatch === false ? 'is-invalid' : ''}`}
                                                                placeholder="Confirm password"
                                                                value={formValues.confirmPassword}
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                }}
                                                            />
                                                            {(formValues.confirmPasswordValid === false || validatePasswordMatch === false) && <div className="invalid-feedback text-start">Passwords must match.</div>}
                                                        </Form.Group>
                                                        <span onClick={toggleShowConfirmPassword} className="position-absolute end-0 translate-middle-y me-5" style={{ top: '60px' }}>
                                                            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={5}>
                            <Card className='mb-2 rounded-4' style={{ position: 'sticky', top: '18vh' }}>
                                <Card.Body>
                                    <h3 className='text-start fw-bold fs-3 pb-2'>Order Summary</h3>
                                    <Row>
                                        <Col className='text-start'>Quantity</Col>
                                        <Col className="text-end fs-4 fw-bold">{totalQuantity} item{totalQuantity > 1 ? 's' : ''}</Col>
                                    </Row>
                                    <Row>
                                        <Col className='text-start'>Subtotal</Col>
                                        <Col className="text-end fs-4 fw-bold">Rs {totalMRP.toFixed(2)}</Col>
                                    </Row>
                                    <Row>
                                        <Col className='text-start'>Discount</Col>
                                        <Col className="text-end  fs-4 fw-bold">Rs {totalDiscount.toFixed(2)}</Col>
                                    </Row>
                                    <Row>
                                        <Col className='text-start'>Shipping</Col>
                                        <Col className="text-end  fs-4 fw-bold">Rs  {shippingPrice === 0 ? 'Enter Pincode to calculate' : `₹${shippingPrice}`}</Col>
                                    </Row>
                                    <hr />
                                    <Row className="mt-3">
                                        <Col className='text-start'>Total Payable</Col>
                                        <Col className="text-end fs-5 fw-bold">
                                            <strong>Rs {(totalAmount + shippingPrice).toFixed(2)}</strong>
                                        </Col>
                                    </Row>

                                    <hr />
                                    {/* <Form className="d-flex mt-3 py-2">
                                        <InputGroup className="w-75">
                                            <FormControl
                                                type="text"
                                                placeholder="Add Promo Code"
                                                className="bg-light border-0  rounded-5 me-2"
                                                value={promoCode}
                                                onChange={(e) => setPromoCode(e.target.value)}
                                            />
                                        </InputGroup>
                                        <Button variant="dark" className="bg-black rounded-5 w-25 justify-content-center py-3 fw-bold" onClick={handleApplyPromoCode}>
                                            <AiOutlineTag size={20} className='me-2' /> Apply
                                        </Button>
                                    </Form> */}
                                    <h5 className='text-start fs-5'>Payment Method</h5>
                                    <p className='text-start fs-6'>All transactions are secure and encrypted.</p>
                                    <Card className="my-3 p-3">
                                        <Card.Title className='fs-5'>Razorpay Secure (UPI, Cards, Wallets, NetBanking)</Card.Title>
                                        <Card.Text>
                                            <img src={razorPay} alt="Razorpay" style={{ width: '100%' }} />
                                        </Card.Text>
                                    </Card>
                                    <Button
                                        disabled={!isFormValid}
                                        onClick={handlePayment}
                                        variant="dark"
                                        className='rounded-5 w-100 py-3 justify-content-center bg-black'>
                                        Proceed with payment
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
};

export default Checkout;