import React, { useState, useContext, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { IoStar } from 'react-icons/io5';
import { TbHeartPlus, TbHeartCheck } from "react-icons/tb";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { CartContext } from '../../Utils/CartContext';
import { WishlistContext } from '../../Utils/WishlistContext';
import { useNavigate } from 'react-router-dom';
import SweetAlert2 from 'react-sweetalert2';
import SecureLS from "secure-ls";
import './ProductCard.css';

const ProductCard = ({ item, baseUrl, wishlist, cart }) => {
    const ls = new SecureLS({ encodingType: 'aes' });
    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToTop();
    }, [navigate]);

    const { addToCart } = useContext(CartContext);
    const { addToWishlist, removeFromWishlist } = useContext(WishlistContext);

    const [isAddedToCart, setIsAddedToCart] = useState(false);
    const [showLoadingIconCart, setShowLoadingIconCart] = useState(false);
    const [isAddedToWishlist, setIsAddedWishlist] = useState(wishlist === 1);
    const [showLoadingIconWishlist, setShowLoadingIconWishlist] = useState(false);
    const [swalProps, setSwalProps] = useState({});
    const baseURL = 'https://theattarco.com/';

    const handleAddToCart = async () => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        setShowLoadingIconCart(true);

        let success = false;
        if (username == null) {
            if (sessionId !== null) {
                success = await addToCart(sessionId, 'na', item);
            }
        } else {
            success = await addToCart('na', username, item);
        }

        setShowLoadingIconCart(false);
        if (success) {
            setIsAddedToCart(true);
            setSwalProps({
                show: true,
                title: `Successfully added ${item.prod_name} to the cart`,
                icon: 'success',
            });
        } else {
            console.error('Failed to add to cart');
        }
    };

    const handleAddToWishlist = async () => {
        setShowLoadingIconWishlist(true);
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = item.price[0];
        const success = username
            ? await addToWishlist('na', username, { ...item, selectedProdCode: selectedPriceDetails.prodcode })
            : await addToWishlist(sessionId, 'na', { ...item, selectedProdCode: selectedPriceDetails.prodcode });

        setShowLoadingIconWishlist(false);
        if (success) {
            setIsAddedWishlist(true);
        } else {
            console.error('Failed to add to wishlist');
        }
    };

    const handleRemoveFromWishlist = async () => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = item.price[0];
        const success = username
            ? await removeFromWishlist('na', username, { ...item, selectedProdCode: selectedPriceDetails.prodcode })
            : await removeFromWishlist(sessionId, 'na', { ...item, selectedProdCode: selectedPriceDetails.prodcode });

        if (success) {
            setIsAddedWishlist(false);
        } else {
            console.error('Failed to remove from wishlist');
        }
    };

    const generateSlug = (name) => {
        return name.toLowerCase().replace(/ /g, '-');
    };

    return (
        <>
            <Card className='me-1 mb-1 bg-transparent' style={{ borderRadius: '10px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
                <Card.Body className="p-0">
                    <div className="w-100 px-2 d-flex align-items-center justify-content-between" style={{ position: 'absolute', top: '10px', zIndex: '1' }}>
                        {item?.price?.[0]?.average_rating > 0 &&
                            <div className='bg-light rounded-3 px-2 py-1 d-flex align-items-center'>
                                <IoStar style={{ color: 'gray' }} />
                                <span className="ml-1 ms-1" style={{ color: 'gray', fontSize: '12px' }}>{String(item?.price?.[0]?.average_rating)}</span>
                            </div>
                        }
                    </div>
                    <a href={`/product/${generateSlug(item?.name)}`} className='text-decoration-none text-black'>
                        <div className="product-img-container" style={{ width: '100%', height: '40vh' }}>
                            <Card.Img
                                src={`${baseURL}${item.images[0]}?w=10`} // Low-resolution placeholder
                                srcSet={`
                                    ${baseURL}${item.images[0]}?w=200 200w, 
                                    ${baseURL}${item.images[0]}?w=400 400w, 
                                    ${baseURL}${item.images[0]}?w=800 800w
                                `}
                                sizes="(max-width: 576px) 200px, (max-width: 768px) 400px, 800px"
                                alt="Product Image"
                                className='lazyload rounded-top-3 rounded-bottom-0 product-img'
                                loading="lazy"
                                width="200" // Set an explicit width
                                height="600" // Set an explicit height
                            />
                        </div>
                        <Card.Body className="px-2 py-2 w-100 d-flex justify-content-between bg-white rounded-bottom-pc">
                            <div>
                                <Card.Title className='text-start mb-0 fw-bold product-name'>{item.name}</Card.Title>
                                <Card.Text className='text-start text-black product-price'>
                                    From Rs. {item.price[0].discountedPrice}
                                </Card.Text>
                            </div>
                        </Card.Body>
                    </a>
                </Card.Body>
            </Card>
            <SweetAlert2 {...swalProps}
                onConfirm={() => {
                    navigate('/cart');
                }}
            />
        </>
    );
};

export default ProductCard;
